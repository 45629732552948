.contact_container {
    padding: 15px;
    .contact_phrase, .contact_capture {
        height: 750px;
    }

    .contact_phrase {
        h5 {
            color: #67D4FE;
            font-family: 'Rubik', sans-serif;
            font-size: 24px;
            font-weight: bold;
        }

        h6 {
            color: #FFFFFF;
            font-family: 'Roboto', sans-serif;
            font-size: 36px;
            font-weight: bold;
        }
        p {
            color: #FFFFFF;
            font-family: 'Roboto', sans-serif;
            font-size: 16px;
            font-weight: 300;
        }
    }
}



@media only screen and (min-width: 772px) and (max-width: 992px) {
    .contact_phrase {
        max-height: 250px;
    }
}



@media only screen and (max-width: 772px) {
  .contact_phrase {
      max-height: 250px;
  }
}