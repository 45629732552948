.conceptComp {
    background-color: #ffffff;
    height: 575px;
    
    .conceptListo360 {
        text-align: center;
        height: 230px;
        
        h3 {
            color: #593ee5;
            font-family: 'Rubik', sans-serif;
            font-weight: bold;
            font-size: 24px;
        }

        h4 {
            color: #3e3e3e;
            font-family: 'Roboto', sans-serif;
            font-weight: bold;
            font-size: 36px;
        }

        p {
            color: #000000;
            font-family: 'Roboto', sans-serif;
            font-size: 16px;
            font-weight: 300;
        }
    }

    .tripod_row {
        margin-top: 65px;
    }
    
    .tripod_Listo360 {
        height: 320px;
        text-align: center;
        
        p {
            color:#464646;
            font-family: 'Rubik', sans-serif;
            font-weight: bold;
            font-size: 24px;
        }

        span {
            color:#6b6b6b;
            font-family: 'Roboto', sans-serif;
            font-weight: bold;
            font-size: 16px;
        }
    }
}


@media only screen and (max-width: 772px) {
    .conceptComp {
        height: 1250px;
        background-color: #ffffff;
        
        .conceptListo360 {
            text-align: center;
            height: 230px;
        }
        
        .tripod_Listo360 {
            text-align: center;
            height: 320px;
        }
    }
}