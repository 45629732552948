.digitalComp {
    height: 1305px;
    background: url("../../assets/bg-jornada-min.jpg");
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: contain;

    .digitalListo360 {
        margin-top: 150px;
        text-align: center;
        height: 265px;
        
        h5 {
            color: #593ee5;
            font-family: 'Rubik', sans-serif;
            font-size: 24px;
            font-weight: bold;
        }

        h6 {
            color: #3e3e3e;
            font-family: 'Roboto', sans-serif;
            font-size: 36px;
            font-weight: bold;
        }

        p {
            color: #000000;
            font-family: 'Roboto', sans-serif;
            font-size: 16px;
            font-weight: 300;
        }
    }

    .features_Listo360 {
        background: #ffffff;
        border-radius: 50px;
        box-shadow: 0px 26px 15px 0px rgba(0,0,0,.26);
        box-sizing: border-box;
        height: 320px;
        margin: 22px 25px 22px 22px;
        max-width: 238px;
        padding: 36px 10px;
        text-align: center;
        width: 100%;

        img {
            margin-bottom: 15px;
        }

        .first {
            color: #464646;
            font-family: 'Rubik', sans-serif;
            font-size: 24px;
            font-weight: 700;
        }

        .second {
            color: #464646;
            font-family: 'Rubik', sans-serif;
            font-size: 24px;
            font-weight: 400;
        }
    }
}

@media only screen and (min-width: 772px) and (max-width: 992px) {
    .digitalComp {
        height: 1875px;

        .features_Listo360 {
            max-width: 305px;
        }
    }
}


@media only screen and (max-width: 772px) {
    .digitalComp {
        height: 3425px;
        background: url("../../assets/bg-jornada-min.jpg");
        background-position: bottom center;
        background-repeat: no-repeat;
        background-size: contain;
      
        .digitalListo360 {
            text-align: center;
            height: 235px;
        }
    
        .features_Listo360 {
            text-align: center;
            width: 100%;
            max-width: 350px;
            height: 320px;
        }
    }
}