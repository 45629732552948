.useComp {
    height: 1750px;
    
    .flexibility, .applications {
        height: 520px;
    }
    
    .flexibility {
        margin-top: 100px;
        h3 {
            color: #593ee5;
            font-family: 'Rubik', sans-serif;
            font-weight: bold;
            font-size: 24px;
        }

        h4 {
            color: #3e3e3e;
            font-family: 'Roboto', sans-serif;
            font-weight: bold;
            font-size: 36px;
            line-height: 35px;
            margin-bottom: 35px;
        }

        p {
            color: #000000;
            font-family: 'Roboto', sans-serif;
            font-size: 16px;
            font-weight: 300;

            strong {
                font-weight: 700;
            }
        }
    }

    .applications {
        img {
            margin-top: 50px;
            width: 550px; 
            height: 497px;
        }
    }

    .use_Listo360 {
        background: #5927E2;
        border-radius: 50px;
        box-shadow: 0px 26px 15px 0px rgba(0,0,0,.26);
        box-sizing: border-box;
        height: 430px;
        margin: 35px 12px;
        max-width: 255px;
        padding: 30px;
        text-align: left;
        width: 100%;

        .fa-building, 
        .fa-mug-hot,
        .fa-globe,
        .fa-layer-group,
        .fa-truck,
        .fa-home,
        .fa-user-friends,
        .fa-cog {
            padding: 15px;
            box-shadow: 0px 26px 15px 0px rgba(0,0,0,.26);
            border-radius: 35px;
            color: #67D5FF;            
            height: 125px;
            margin-bottom: 15px;
            width: 125px;
        }

        .use_img {
            text-align: center
        }

        h5 {
            color: #67d5ff;
            font-family: 'Rubik', sans-serif;
            font-size: 24px;
            font-weight: 700;
        }

        p {
            color: #FFFFFF;
        }
    }
}


@media only screen and (min-width: 772px) and (max-width: 992px) {
   .useComp {
       height: 2750px;

       .flexibility{
        height: 520px;
       }

       .applications {
        display: none;
       }

       .use_Listo360 {
           max-width: 325px;
       }
   }
}




@media only screen and (max-width: 772px) {
    .useComp {
        height: 4250px;
        
        .flexibility, .applications {
            height: 520px;
        }
        
        .flexibility {
            margin-top: 100px;
        }
    
        .applications {
            height: 390px;
            margin-top: 100px;
            img {
                margin-top: 25px auto 15px;
                padding-left: 15px;
                width: 100%;
                margin: auto;
                height: 320px;
            }
        }
    
        .use_Listo360 {
            height:350px;
            width: 100%;
            max-width: 350px;
            margin: 22px 25px 22px 22px;
            
        }
    }
}