.newsletter {
      margin-bottom: 50px;
            height: 415px;
    .newsletter_container {
        .newsletter_listo360 {
            .newsletter_form {
                background: #ffffff;
                border-radius: 50px;
                box-shadow: 0px 26px 15px 0px rgba(0,0,0,.26);
                box-sizing: border-box;
                height: 350px;
                padding: 50px;
                h5 {
                    color: #593ee5;
                    font-family: 'Rubik', sans-serif;
                    font-size: 24px;
                    font-weight: bold;
                }
        
                h6 {
                    color: #3e3e3e;
                    font-family: 'Roboto', sans-serif;
                    font-size: 36px;
                    font-weight: bold;
                }

                .mailchimp_form {
                    
                    input {
                        border: none;
                        border-bottom: solid #d4d4d4 1px;
                        color: #464646;
                        font-family: 'Roboto', sans-serif;
                        font-weight: 300;
                        font-size: 16px;
                        width: 460px;
                        height: 35px;
                        margin: 25px 0px 25px 0px;

                        &:focus {
                            outline: none;
                            border-bottom: solid #9c27b0 1.5px;
                        }
                    }

                    button {
                        background: #593EE5;
                        border: none;
                        border-radius: 5px;
                        color: #ffffff;
                        cursor: pointer;
                        font-family: 'Roboto', sans-serif;
                        font-size: 16px;
                        padding: 15px 25px;
                        position: absolute;
                        right: 75px;
                        bottom: 55px;
                        &:focus {
                            outline: none;
                        }
                    }

                }
            }
        }
    }
}

@media only screen and (max-width: 772px) {

    .newsletter {
        margin-top: 100px;
        margin-bottom: 50px;
        height: 375px;
        .newsletter_container {
            .newsletter_listo360 {
                .newsletter_form {
                    background: #ffffff;
                    border-radius: 50px;
                    box-shadow: 0px 26px 15px 0px rgba(0,0,0,.26);
                    box-sizing: border-box;
                    height: 410px;
                    padding: 50px;
                    h5 {
                        color: #593ee5;
                        font-family: 'Rubik', sans-serif;
                        font-size: 24px;
                        font-weight: bold;
                    }
            
                    h6 {
                        color: #3e3e3e;
                        font-family: 'Roboto', sans-serif;
                        font-size: 36px;
                        font-weight: bold;
                    }
    
                    .mailchimp_form {
                        
                        input {
                            border: none;
                            border-bottom: solid #d4d4d4 1px;
                            color: #464646;
                            font-family: 'Roboto', sans-serif;
                            font-weight: 300;
                            font-size: 16px;
                            max-width: 250px;
                            height: 35px;
                            margin: 25px auto;
    
                            &:focus {
                                outline: none;
                                border-bottom: solid #9c27b0 1.5px;
                            }
                        }
    
                        button {
                            background: #593EE5;
                            border: none;
                            border-radius: 5px;
                            color: #ffffff;
                            cursor: pointer;
                            font-family: 'Roboto', sans-serif;
                            font-size: 16px;
                            padding: 15px 25px;
                            position: absolute;
                            right: 75px;
                            bottom: 35px;
                            &:focus {
                                outline: none;
                            }
                        }
    
                    }
                }
            }
        }
    }


}
            