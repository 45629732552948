.footer {
    background: url("../../assets/bg-footer-min.jpg");
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: auto 2100px;
    height: 1445px;
    margin-bottom: -500px;
    
    .footer_container {
        max-height: 150px;
        height:150px;

        .separator {
            margin-bottom: 15px;
            hr {
                border: solid #67D4FE .5px;
            }
        }

        .footer_col {
            
            .direitos {
                margin-top: 15px;   
                p {
                    color: #67D4FE;
                }
            }

            .contact_span {
                margin-top: 15px;
                
                span {
                    color: #ffffff;
                    font-size: 24px;
                    font-family: 'Rubik', sans-serif;
                    font-weight: bold; 
                }
                
            }
            
            ul {
                margin-top: 15px;
                padding: 0;
               
                li {
                    display: inline-block;
                    list-style-type: none;
                    padding-right: 10px;


                    a {
                    color: #67D4FE;
                    text-decoration: none;
                    }
                }
            }
            
            .fa-linkedin-in,
            .fa-facebook-f,
            .fa-instagram,
            .fa-youtube {
                color: #FFFFFF;            
                height: 35px;
                margin: 15px 0 0 15px;
                width: 35px;
            }
        }
    }
}




@media only screen and (min-width: 772px) and (max-width: 992px) {
    .footer {
        height: 1800px;
    }
 }



@media only screen and (max-width: 772px) {
    .footer {
        background-size: auto 2600px;
        height: 1800px;
        .footer_container {
            max-height: 350px;
            height:350px;
        }
    }
}

