.introComp {
    background: url("../../assets/bg-header-min.jpg");
    background-position: top center;
    background-repeat: no-repeat;
    height: 1050px;

    .routine, .devices {
        height: 1050px;
    }
    
    .routine {
        padding: 200px 0 0 100px;
        .main_text {
            color: #FFFFFF;
            h1 {
                font-size: 35px;
                font-weight: bold;
                font-family: 'Rubik', sans-serif;
            }
            h2 {
                font-size: 24px;
                font-family: 'Roboto', sans-serif;
            }
        }

        .demo {
            margin-top: 50px;
            
            .demo_btn {
                background: #48c2f1;
                border-radius: 99px;
                color: #ffffff;
                cursor: pointer;
                font-family: 'Roboto', sans-serif;    
                font-weight: bold;
                font-size: 16px;
                padding: 15px 30px;
                text-decoration: none;
                &:hover {
                    background: #ffffff;
                    color: #4AC2F1;  
                }
            }
        }
        
        .ver_mais {
            margin-top: 50px;

            .ver_mais_btn {
                z-index: 9999;
                border: solid #ffffff 2px;
                border-radius: 99px;
                color: #ffffff;
                cursor: pointer;
                font-family: 'Roboto', sans-serif;
                padding: 15px 30px;

                &:hover {
                    border: solid #4AC2F1 2px;
                    color: #4AC2F1;  
                }
            }
        }
    }

    .devices {
        padding: 450px 0 0 50px;
        img {
            width: 675px;
            height: 465px;
        }
    }
}

@media only screen and (min-width: 772px) and (max-width: 992px) {
    
    
    .routine {
        .gota {
            display: none;
        }
    }
    
    
    .devices {
        padding: 450px 0 0 50px;
        img {
           display: none;
        }
    }
}


@media only screen and (max-width: 772px) {
    .introComp {
        max-height: 1000px;
        
        .routine, .devices {
            max-height: 500px;
        }
        
            .routine {
                max-height: 650px;
                padding: 100px 100px 0 55px;
                .main_text {
                    color: #FFFFFF;
                    h1 {
                        font-size: 35px;
                    }
                    h2 {
                        font-size: 24px;
                    }
                }
        
                .demo{
                   width: 235px;    
                }

                .gota {
                    display: none;
                }
            }
        
            .devices {
                padding: 90px 100px 0 100px;
                img {
                    width: 260px;
                    height: 210px;
                }
            }
    }
}